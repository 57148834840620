import { 
    Container, Box, IconButton, HStack, Spacer, Text, useColorMode, useColorModeValue,   
    Menu, MenuButton, MenuList, MenuItem, useDisclosure, Button,
    MenuDivider, useBreakpointValue,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon, HamburgerIcon, SettingsIcon, CloseIcon } from '@chakra-ui/icons';
import { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom"; // Import NavLink and useNavigate from react-router-dom
import { MdEnergySavingsLeaf, MdSpaceDashboard } from "react-icons/md";
import { IoDocument } from "react-icons/io5";
import { FaRobot, FaPlayCircle, FaSignOutAlt, FaSignInAlt } from "react-icons/fa";
import { FcNews, FcSearch, FcUpload, FcGlobe  } from "react-icons/fc";
import { useGlobal } from "../contexts/GlobalContext";

import { useLocation } from 'react-router-dom'; // useLocation 추가

const NavigationBar = ({checkExpireTime, domainMenu, shouldMenuRender, setShouldMenuRender}) => {
    const { toggleColorMode } = useColorMode();
    const [nightMode, setNightMode] = useState(true);
    const navigate = useNavigate();
    const location = useLocation()
    const [tokenAndSessionInfo, setTokenAndSessionInfo] = useState({});
    
    const { isSignedIn, setIsSignedIn } = useGlobal();

    const { isOpen: isControlCenterOpen, onOpen: onControlCenterOpen, onClose: onControlCenterClose } = useDisclosure();

    const handleToggleNightMode = () => {
        setNightMode(!nightMode);
        toggleColorMode();
    };

    const navigationBarBg = useColorModeValue('#e9e9e9', '#333333');
    const navigationBarColor = useColorModeValue('black', 'white');
    const controlCenterBg = useColorModeValue('#333333', '#e9e9e9');
    const controlCenterColor = useColorModeValue('white', 'black');
    const selectedPageColor = useColorModeValue('blue.400', 'orange');

    const [currentPage, setCurrentPage] = useState(window.location.pathname);

    useEffect(() => {
        if (!isSignedIn && currentPage === '/rag') {
            // 로그인하지 않았을 때는 현재 위치를 저장하고 로그인 페이지로 이동
            navigate('/signin', { state: { from: location } });
        } else if (isSignedIn && currentPage === '/signin') {
            // 로그인 후 리다이렉트할 경로가 저장되어 있으면 그곳으로 이동, 없으면 기본 경로(/)
            const redirectPath = location.state?.from?.pathname || '/';
            navigate(redirectPath);
        }
    }, [isSignedIn, currentPage, navigate, location]);

    const handleSignOut = () => {
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('session-exp');
        localStorage.removeItem('access-token-exp');
        localStorage.removeItem('refresh-token-exp');
        setIsSignedIn(false);
        navigate('/signin');
    };

    const check = async () => {
        const result = await checkExpireTime();
        if (result === false) {
            navigate('/signin')
        }
        setTokenAndSessionInfo(result);
    };

    useEffect(() => {
        const handleLocationChange = () => {
            setCurrentPage(window.location.pathname);
        };
    
        window.addEventListener('popstate', handleLocationChange);
    
        return () => {
            window.removeEventListener('popstate', handleLocationChange);
        };
    }, []);

    const disappearComponent = useBreakpointValue({ base: true, lg: false });
    

    const handleMenuRender = (renderMenu) => {
        console.log(domainMenu);

        if (domainMenu) {
            
            if (renderMenu === 'navbar' && !isSignedIn) {
                if (domainMenu?.home === 'ChatPage') {
                    let navBarMenu = [];
                    Object.entries(domainMenu?.menu).forEach(([key, value]) => {
                        if (key === 'ChatPage' && value === true) {
                            navBarMenu.push(
                            <Button as={Link} to="/" leftIcon={<FcSearch />} bg='transparent' key={key}>
                                {key}
                            </Button>                    
                            );                  
                        } else if (key === 'PlaygroundPage' && typeof value === 'object') {
                            navBarMenu.push(
                            <Button as={Link} to="/playground" leftIcon={<FcGlobe />} bg='transparent' key={key}>
                                {key}
                            </Button>
                            );                    
                        }
                    });
                    return navBarMenu;
                } else if (domainMenu?.home === 'PlaygroundPage') {
                    let navBarMenu = [];
                    Object.entries(domainMenu?.menu).forEach(([key, value]) => {
                        if (key === 'PlaygroundPage' && value === true) {
                            navBarMenu.push(
                            <Button as={Link} to="/" leftIcon={<FcGlobe />} bg='transparent' key={key}>
                                {key}
                            </Button>                    
                            );                  
                        } 
                    });
                    return navBarMenu;
                }
            } else if (renderMenu === 'navbar' && isSignedIn) {
                let navBarMenu = [];
                Object.entries(domainMenu?.menu).forEach(([key, value]) => {
                    if (key === 'ChatPage' && value === true) {
                        navBarMenu.push(
                        <Button as={Link} to="/" leftIcon={<FcSearch />} bg='transparent' key={key}>
                            {key}
                        </Button>                    
                        );                  
                    } else if (key === 'PlaygroundPage' && value) {
                        navBarMenu.push(
                        <Button as={Link} to="/playground" leftIcon={<FcGlobe />} bg='transparent' key={key}>
                            {key}
                        </Button>
                        );                  
                    } else if (key === 'RagPage' && value === true) {
                        navBarMenu.push(
                        <Button as={Link} to="/rag" leftIcon={<FcUpload />} bg='transparent' key={key}>
                            {key}
                        </Button>                    
                        );                  
                    }
                });
                return navBarMenu;
            }
    
            // 동적으로 햄버거 메뉴 렌더링
            if (renderMenu === 'hamburger' && !isSignedIn) {
                let hamburgerMenu = [];
                Object.entries(domainMenu?.menu).forEach(([key, value]) => {
                    if (key === 'ChatPage' && value === true) {
                        hamburgerMenu.push(
                        <MenuItem as={NavLink} to="/" key={key} icon={<FcSearch />}>
                            Chat
                        </MenuItem>
                        );
                    } else if (key === 'PlaygroundPage' && typeof value === 'object') {
                        hamburgerMenu.push(
                        <MenuItem as={NavLink} to="/playground" key={key} icon={<FcGlobe />}>
                            Playground
                        </MenuItem>
                        );
                    }
                });
                return hamburgerMenu;
            } else if (renderMenu === 'hamburger' && isSignedIn) {
                let hamburgerMenu = [];
                Object.entries(domainMenu?.menu).forEach(([key, value]) => {
                    if (key === 'ChatPage' && value === true) {
                        hamburgerMenu.push(
                        <MenuItem as={NavLink} to="/" key={key} icon={<FcSearch />}>
                            Chat
                        </MenuItem>
                        );
                    } else if (key === 'PlaygroundPage' && value) {
                        hamburgerMenu.push(
                        <MenuItem as={NavLink} to="/playground" key={key} icon={<FcGlobe />}>
                            Playground
                        </MenuItem>
                        );
                    } else if (key === 'RagPage' && value === true) {
                        hamburgerMenu.push(
                        <MenuItem as={NavLink} to="/rag" key={key} icon={<FcUpload />}>
                            RAG Management
                        </MenuItem>
                        );
                    }
                });
                return hamburgerMenu;
            }
        } else {
            return (
                <Button as={NavLink} to="/" icon={<FaRobot />}>
                    로딩중
                </Button>
            );
        }
    };

    return (
        <Container
            maxW='100%'
            size="container.3xl"
            h='6vh'
            bg={navigationBarBg}
            color={navigationBarColor}
        >
            <HStack alignItems="center" h="100%">
                <HStack spacing={40}>
                <Link to="/">
                <img 
                    src='/ci_didim365_en.png' // Assuming the image is located in the public folder
                    alt='Didim365 Logo'
                    style={{ width: '150px', height: 'auto' }} 
                />
                </Link>    
                {!disappearComponent && (
                    <>
                    {!isSignedIn && (
                    <HStack spacing={10}>
                        {handleMenuRender('navbar')}            
                    </HStack>              
                    )}
                    
                    {isSignedIn && (
                    <HStack spacing={10}>
                        {handleMenuRender('navbar')}                    
                    </HStack>              
                    )}
                    </>
                )}                                
                </HStack>                
                <Spacer />
                <Box>
                    <IconButton 
                        icon={isSignedIn? <FaSignOutAlt/>:<FaSignInAlt/> }
                        bg='transparent'
                        onClick={isSignedIn? ()=>{handleSignOut()} : ()=>{navigate('/signin')}}
                    />
                    <IconButton
                        icon={nightMode ?  <MoonIcon /> : <SunIcon />}
                        onClick={handleToggleNightMode}
                        bg='transparent'
                    />
                    {isSignedIn && (
                    <IconButton 
                        icon={<SettingsIcon />} 
                        onClick={()=>{
                            check()
                            if (!isControlCenterOpen) {
                                onControlCenterOpen();
                            } else {
                                onControlCenterClose();
                            }
                        }} 
                        aria-label="Settings"
                        bg='transparent'
                    />
                    )}                    
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label='Options'
                            icon={<HamburgerIcon />}
                            variant='outline'
                            borderWidth='0px'
                            bg='transparent'
                        />
                        <MenuList>
                            {!isSignedIn && (
                                <>            
                                    {handleMenuRender('hamburger')}
                                </>                                
                            )}                   
                            {isSignedIn && (
                                <>
                                    {handleMenuRender('hamburger')}
                                </>
                            )}
                        </MenuList>
                    </Menu>
                </Box>
            </HStack>
            {isControlCenterOpen && (
                <Box
                    position="fixed"
                    top="16"
                    right="4"
                    width="300px"
                    padding="4"
                    backgroundColor={controlCenterBg}
                    color={controlCenterColor}
                    borderRadius="13px"
                    boxShadow="lg"
                    backdropFilter="blur(10px)"
                    zIndex="10"
                    opacity="1"
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Text fontWeight="bold">Control Center</Text>
                        <IconButton 
                            icon={<CloseIcon />} 
                            size="sm" 
                            onClick={onControlCenterClose} 
                            aria-label="Close"
                            backgroundColor="transparent"
                            _hover={{ backgroundColor: "whiteAlpha.300" }}
                        />
                    </Box>
                    <Text mt="2">
                    세션 만료시간
                    <br />
                    {tokenAndSessionInfo.sessionExpireTime? tokenAndSessionInfo.sessionExpireTime: '세션 만료시간을 확인할 수 없습니다.'}
                    </Text>
                    <Text mt="2">
                    엑세스 토큰 만료시간
                    <br />
                    {tokenAndSessionInfo.accessTokenExpireTime? tokenAndSessionInfo.accessTokenExpireTime: '엑세스 토큰 만료시간을 확인할 수 없습니다.'}
                    </Text>
                    <Text mt="2">
                    리프레시 토큰 만료시간
                    <br /> 
                    {tokenAndSessionInfo.refreshTokenExpireTime? tokenAndSessionInfo.refreshTokenExpireTime: '리프레시 토큰 만료시간을 확인할 수 없습니다.'}
                    </Text>
                </Box>
            )}
            
        </Container>
        
    );
};

export default NavigationBar;
